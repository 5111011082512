(($) => {
  const AFSvg = {
    item: $('#js-svg'),
    init: () => {
      if (AFSvg.item.length) {
        AFSvg.setup();
      }
    },
    setup: () => {
      AFSvg.item.load('/svg/defs.svg');
    },
  };

  $(AFSvg.init());
})(jQuery);
