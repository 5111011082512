(($) => {
  const jsContactForm = $('#js-contact-form');
  const AFContactForm = {
    item: jsContactForm,
    fieldset: jsContactForm.find('fieldset'),
    btn: jsContactForm.find('button'),
    isSending: false,
    init: () => {
      if (AFContactForm.item.length) {
        AFContactForm.setup();
      }
    },
    setup: () => {
      AFContactForm.item.on('submit', (e) => {
        e.preventDefault();
        AFContactForm.sendMsg();
      });
    },
    toggleSending: () => {
      AFContactForm.isSending = !AFContactForm.isSending;
      AFContactForm.btn.attr('disabled', AFContactForm.isSending);
    },
    sendMsg: () => {
      AFContactForm.toggleSending();
      $.post('/contacto', AFContactForm.item.serialize(), (response) => {
        AFContactForm.showAlert(response.stat, response.data.join('\n'));
        AFContactForm.item[0].reset();
      }).fail(() => {
        AFContactForm.showAlert('ko');
      }).always(() => {
        AFContactForm.toggleSending();
      });
    },

    showAlert: (success = 'ko', msg) => {
      const alertMsg = $(`
        <div class="c-alert${success === 'ok' ? ' c-alert--success' : ''}">
          <p>${msg || 'Ha ocurrido un error. Por favor, inténtelo de nuevo pasados unos minutos'}</p>
        </div>
      `);
      AFContactForm.fieldset.prepend(alertMsg);
    },

  };

  $(AFContactForm.init());
})(jQuery);
