/*  global google:true*/

const AFMap = {
  item: $('#js-map'),
  instance: null,
  init: () => {
    if (AFMap.item.length) {
      AFMap.setup();
    }
  },
  setup: () => {
    AFMap.instance = new google.maps.Map(AFMap.item.get(0), {
      center: { lat: 40.4947358, lng: -3.720577300000059 },
      zoom: 16,
      scrollwheel: false,
    });
    AFMap.addMarker();
  },
  addMarker: () => {
    const marker = new google.maps.Marker({
      map: AFMap.instance,
      animation: google.maps.Animation.DROP,
      label: 'fisioterapia ArroyoFresno',
      position: new google.maps.LatLng(40.4947358, -3.720577300000059),
    });
    marker.info = new google.maps.InfoWindow({
      content: `<p>${marker.label}</p>`,
    });
    google.maps.event.addListener(marker, 'click', () => {
      marker.info.open(Map.obj, marker);
    });
  },
};
