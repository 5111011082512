(($) => {
  const AFMobileMenu = {
    item: $('#js-mobile-menu'),
    toggler: $('#js-toggler'),
    init: () => {
      if (AFMobileMenu.item.length && AFMobileMenu.toggler.length) {
        AFMobileMenu.setup();
      }
    },
    setup: () => {
      AFMobileMenu.toggler.on('click', () => {
        AFMobileMenu.item.toggleClass('l-hidden');
      });
    },
  };

  $(AFMobileMenu.init());
})(jQuery);
