(($) => {
  const AFSlider = {
    item: $('#js-slider'),
    init: () => {
      if (AFSlider.item.length) {
        AFSlider.setup();
      }
    },
    setup: () => {
      AFSlider.item.slick({
        dots: true,
        autoplay: true,
        autoplaySpeed: 6000,
      });
    },
  };

  $(AFSlider.init());
})(jQuery);
